import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import he from 'he';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faCalendar, faEye } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from 'react-share';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [postDates, setPostDates] = useState({});
  const [postCategories, setPostCategories] = useState({}); // Nouvel état pour stocker les catégories
  const [postVisits, setPostVisits] = useState({});

  const perPage = 8;

  const incrementArticleVisits = async (postId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/increment-article-visits/${postId}`;
      await axios.post(url);
    } catch (error) {
      console.error(`Error incrementing article visits for post ${postId}:`, error);
    }
  };
  
  const fetchPostVisits = async (postId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/post-visits/${postId}`;
      const response = await axios.get(url);
      const visits = response.data;
      setPostVisits((prevVisits) => ({
        ...prevVisits,
        [postId]: visits,
      }));
    } catch (error) {
      console.error(`Error fetching post visits for post ${postId}:`, error);
    }
  };
  
  const generateWhatsAppLink = (text) => {
    const encodedText = encodeURIComponent(text);
    return `https://wa.me/?text=${encodedText}`;
  };

  const handleFacebookShare = () => {
    if (posts.length > 0 && posts[0].id) {
      const postId = posts[0].id;
      const postUrl = `${process.env.REACT_APP_API_DOMAIN}/posts/${postId}`;

      if (isValidUrl(postUrl)) {
        // Mettez à jour les balises Open Graph avant de partager sur Facebook

        const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`;
        window.open(facebookLink, '_blank');
      } else {
        console.error('Invalid URL:', postUrl);
      }
    } else {
      console.error('No valid posts found for sharing on Facebook.');
    }
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const fetchPosts = async (page) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/posts?per_page=${perPage}&page=${page}`;
      const response = await axios.get(url);
      //console.log(response);
      //console.log(response.data);
      const fetchedPosts = response.data;
      const { data, headers } = response;
      setTotalPages(Number(headers['x-wp-totalpages']));

      const postsWithVisits = await Promise.all(
        fetchedPosts.map(async (post) => {
          if (post.id) {
            await fetchPostVisits(post.id);
          }
          return post;
        })
      );

      const fetchCategory = async (categoryId) => {
        try {
          const categoryUrl = `${process.env.REACT_APP_API_ROOT}/categories/${categoryId}`;
          const categoryResponse = await axios.get(categoryUrl);
          const categoryData = categoryResponse.data;
          return categoryData.name;
        } catch (error) {
          console.error(`Error fetching category details for category ID ${categoryId}:`, error);
          return '';
        }
      };
      
      const postsWithCategories = await Promise.all(
        fetchedPosts.map(async (post) => {
          if (post.featured_media) {
            const imageUrl = await fetchFeaturedImage(post.featured_media);
            const categories = await fetchPostCategories(post.id);
            const categoryNames = await Promise.all(
              categories.map(async (categoryId) => fetchCategory(categoryId))
            );
  
            // Mettez à jour l'état des catégories
            setPostCategories((prevCategories) => ({
              ...prevCategories,
              [post.id]: categoryNames,
            }));
  
            return { ...post, imageUrl, categories };
          }
          return post;
        })
      );

      const postsWithImages = await Promise.all(
        fetchedPosts.map(async (post) => {

          if (post.featured_media) {
            const imageUrl = await fetchFeaturedImage(post.featured_media);
            const categories = await fetchPostCategories(post.id);
            return { ...post, imageUrl };
          }
          return post;
        })
      );

      setPosts(postsWithImages);
      console.log(posts);

      // Récupérez les dates de création des articles
      const dates = await Promise.all(
        fetchedPosts.map(async (post) => {
          return { id: post.id, date: post.date };
        })
      );

      // Mettez à jour l'état des dates
      const datesObject = dates.reduce((acc, date) => {
        acc[date.id] = date.date;
        return acc;
      }, {});

      setPostDates(datesObject);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const fetchPostCategories = async (postId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/posts/${postId}/categories`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(`Error fetching categories for post ${postId}:`, error);
      return [];
    }
  };

  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  const fetchFeaturedImage = async (mediaId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/media/${mediaId}`;
      const response = await axios.get(url);
      return response.data.source_url;
    } catch (error) {
      console.error(`Error fetching featured image for media ID ${mediaId}:`, error);
      return '';
    }
  };

  const truncateDescription = (description) => {
    if (description.length > 60) {
      return description.substring(0, 60) + '...';
    }
    return description;
  };

  const CustomWhatsappShareButton = ({ posts }) => {
    const message = posts.length > 0 ? he.decode(posts[0].title.rendered) : '';
    const postUrl = posts.length > 0 ? `${process.env.REACT_APP_API_DOMAIN}posts/${posts[0].id}` : '';
    const defaultMessage = "bonjour COACH LEWI";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=22565110441&text=${encodeURIComponent(defaultMessage)}`;
  
    return (
      <button
        className='share-button text-white bg-green-500'
        disabled={posts.length === 0}
        style={{ float: 'right', marginRight: '5px', padding: 8, fontSize: 16 }}
        onClick={() => window.location.href = whatsappUrl}
      >
        <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '5px' }} className='share-icon' />
      </button>
    );
  };
  
  return (
    <>
      <div className="container mx-auto my-auto mb-10 p-1" style={{ marginTop: '-20px' }}>
        <h1 className='lg:w-5/6 xl:w-4/5 2xl:w-3/4 mx-6 py-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-2xl font-bold' style={{ fontSize: 20 }}>DERNIERS ARTICLES</h1>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-5' style={{ marginTop: '-20px' }}>
          {Object.keys(posts).length ? (
            posts.map((post, index) => (

              <div>                  
              <Link to={`/posts/${post.id}`} onClick={() => incrementArticleVisits(post.id)}>
              <img
                    src='https://backoffice.coach-lewi.com/wp-content/uploads/2024/07/36100856_3b69-150x150.jpg'
                    style={{ width: 39, height: 39, borderRadius:'100%', marginBottom:-50, marginLeft:15,position:'relative'}}
                  />
                  <img
                      src={post.featured_src || 'https://via.placeholder.com/500'}
                      alt={post.title.rendered}                      
                    />
                    
                    </Link>
                    <>
                    <div key={post.id} className='card shadow-lg rounded-lg flex flex-col gap-5 p-5 bg-white m-4' style={{marginTop:-40,position:'relative', borderTop:'5px solid #2196f3'}}>
                <Link to={`/posts/${post.id}`} onClick={() => incrementArticleVisits(post.id)}>
                  
                  <h2 className="text-lg font-medium" style={{ fontWeight: '700', fontSize: 16}}>
                    {(he.decode(post.title.rendered))} - <span style={{fontSize:11, color:'gray', fontWeight:'500'}}>{postVisits[post.id]} { postVisits[post.id] > 1 ? 'vues':'vue'} </span>
                  </h2>          
                  </Link>        
                {/*
                  <p style={{ marginTop: -5, marginBottom: 10, fontSize: 12, float:'right' }} className="text-gray-500 text-sm">
                    <FontAwesomeIcon icon={faCalendar} style={{ color: 'rgb(17 24 39)' }} />&nbsp; {new Date(postDates[post.id]).toLocaleDateString()}                    
                  </p>
                 */}

                <center>
                <a
                        href={`https://api.whatsapp.com/send?phone=22565110441&text=Bonjour COACH-LEWI je suis interesse par votre Formation: ${encodeURIComponent(posts.length > 0 ? he.decode(posts[0].title.rendered) : '' + ' ' + posts.length > 0 ? `${process.env.REACT_APP_API_DOMAIN}/posts/${posts[0].id}` : '')}`}
                        title={posts.length > 0 ? he.decode(posts[0].title.rendered) : ''}
                        style={{float:'right'}}
                      >
                      <button
                        className='share-button  text-white bg-green-500'
                        disabled={posts.length === 0}
                        style={{ marginRight: '5px', padding: 8, fontSize: 16 }}
                      >                  
                        <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '5px' }} className='share-icon'/>
                      </button>
                    </a>
                </center>
                

                
                    </div>
                    </>
                </div>


            ))
          ) : (

            <div className='col-span-3 w-5 h-5 rounded-full border-2 border-spacing-2 border-4 border-b-0  animate-spin border-blue-500' />
          )}
        </div>
      </div>
      <center>
        <div className='' style={{ marginBottom: '140px' }}>
          <button
            className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50'
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            style={{ marginRight: '5px', padding: 8, fontSize: 16, background: '#002763' }}
          >
            Précédent
          </button>
          <span style={{ margin: '0 5px', fontSize: 17 }}>{currentPage} sur {totalPages}</span>
          <button
            className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50'
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{ marginLeft: '5px', padding: 8, fontSize: 16, background: '#002763' }}
          >
            Suivant
          </button>
        </div>
      </center>
    </>
  );
};

export default Posts;
