import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaInfoCircle,FaCalendarAlt, FaProjectDiagra, FaImages, FaCalendar, FaAddressBook, FaUserMd, FaFilePdf, FaPrayingHands, FaChurch, FaLaptopCode, FaCode } from 'react-icons/fa';
import ContactsIcon from '@material-ui/icons/Contacts';  // Importez l'icône de Material-UI
import axios from "axios";
//import { FaInfo, FaProjectDiagram, FaCalendar, FaFilePdf } from 'react-icons/fa';
//import { MdContacts, MdLocalHospital } from 'react-icons/md';


const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#2196f3'); // Couleur par défaut #111827

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    // Faites la requête à votre API pour récupérer la couleur de fond
    const url = `${process.env.REACT_APP_API_ROOT_V1}/background-color`;
    axios.get(url)
      .then(response => {
        // Mettez à jour la couleur de fond dans l'état
        setBackgroundColor(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération de la couleur de fond :", error);
      });
  }, []); // Assurez-vous de passer une dépendance vide pour que cela se produise une seule fois au chargement

  return (
    <>
      <nav className={`dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600`} style={{ background: backgroundColor }}>

        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/" className="text-white text-lg font-bold flex items-center">
            {/*<img src="https://www.adcocody.ci/img/logo.png" alt="Logo AD-Excellence.net" className="h-10" /> */}
            <span className="ml-2">
            <FaCode className="inline-block mr-2" />
            
            
            
            COACH-LEWI.COM</span>
          </Link>
          <button
            onClick={toggleMenu}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-multi-level"
            aria-expanded={isMenuOpen}
            style={{ backgroundColor: '#f3f4f6' }}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
          <div className={`w-full md:flex md:w-auto ${isMenuOpen ? 'block' : 'hidden'}`} id="navbar-multi-level">
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
              style={{ background: backgroundColor }}>
              <li>
                <Link
                  to="/presentation/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontWeight: 'bolder', fontSize: 16, color: 'white' }}
                >
                  <FaInfoCircle className="inline-block mr-2" /> Qui suis-je?
                </Link>
              </li>
              
{/**

<li>
                <Link
                  to="/articles/205"
                  className="block mt-4 lg:inline-block lg:mt-0 hover:text-white"
                  style={{ fontWeight: 'bolder', fontSize: 16, color: 'white' }}
                >
                  <FaImages className="inline-block mr-2" /> Portfolio
                </Link>
              </li>


              <li>
                <Link
                  to="/articles/212"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontWeight: 'bolder', fontSize: 16, color: 'white' }}
                >
                  <FaUserMd className="inline-block mr-2" /> Santé
                </Link>
              </li>
 */}
              <li>
                <Link
                  to="/calendrier"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontWeight: 'bolder', fontSize: 16, color: 'white' }}
                >
                      <FaCalendarAlt className="inline-block mr-2" /> Calendrier Evénements

                </Link>
              </li>
              <li>
                <Link
                  to="/catalogue.pdf"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontWeight: 'bolder', fontSize: 16, color: 'white' }}
                >
                  <FaFilePdf className="inline-block mr-2" /> Catalogue [PDF]
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
